import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    unprocessed: {
        backgroundColor: theme.palette.primary.main,
    },
    cleared: {
        backgroundColor: "#00cc66",
    },
    fraud: {
        backgroundColor: "#ff4d4d"
    },
    flagged: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(-0.9),
        padding: theme.spacing(0.3),
        color: "#ff0000",
        backgroundColor: "#ffb3b3",
        borderRadius: 10
    }

}));

