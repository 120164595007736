import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    left: {
        textAlign: 'left'
    },
    right: {
        textAlign: 'right'
    },
    middle: {
        textAlign: 'center',
    },
    secondary: {
        color: theme.palette.text.secondary
    },
    base: {
        color: "white",
        margin: 0,
        borderRadius: 15,
        textTransform: 'capitalize',
        padding: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    paper: {
        textAlign: 'left',
        padding: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    searchInput: {
        width: '90%'
    },
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
        overflowX: "scroll"
    },
}));