import React from 'react';
import { Paper, Typography, Divider, Grid } from '@mui/material';
import { useStyles } from './styles';
import { useStyles as useBaseStyles } from '../../styles';
import clsx from 'clsx';

const History = ({ invoices }) => {
    const classes = useStyles();
    const baseClasses = useBaseStyles();

    return (
        <Paper className={classes.pageContent}>
            <Grid container className={classes.gridInsidePaper}>
                <Grid item xs={12} >
                    <Typography variant="h5" className={baseClasses.left}>
                        History
                    </Typography>
                </Grid>
            </Grid>
            <Divider />


            <Grid container className={clsx(classes.gridInsidePaper, baseClasses.left)}>
                {invoices.map((invoice, index) => (
                    <>
                        {index >= 1 &&
                            <Grid item xs={12} sx={{ my: 2 }}>
                                <Divider />
                            </Grid>
                        }
                        <Grid container key={index}>
                            <Grid item xs={2} lg={2} >
                                {invoice.created_date}
                            </Grid>
                            <Grid item xs={4} lg={4} className={baseClasses.secondary}>
                                <Typography>
                                    Invoice Paid
                                </Typography>
                            </Grid>
                            <Grid item xs={6} lg={6}>
                                <a href={invoice.hosted_invoice_url}>
                                    View
                                </a>
                                {" "}
                                <a href={invoice.invoice_pdf}>
                                    Download
                                </a>
                            </Grid>

                            <Grid item xs={12} sx={{ my: 2 }}>
                                <Divider />
                            </Grid>

                            <Grid item xs={2} lg={2} >
                                {invoice.created_date}
                            </Grid>


                            <Grid item xs={4} lg={4} className={baseClasses.secondary}>
                                <Typography>
                                    Invoice Created
                                </Typography>
                            </Grid>

                        </Grid>
                    </>))
                }


                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Divider />
                </Grid>
            </Grid>


        </Paper >
    );
}

export default History;
