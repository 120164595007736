import React, { useState, useEffect } from "react";
import AppPagination from "../../common/components/common/AppPagination";
import OrderMenu from "./OrderMenu";
import OrderDetail from "./OrderDetail";
import { Box, CircularProgress } from '@mui/material';
import useAxiosPrivate from "../../common/hooks/useAxiosPrivate";
import {
    setQueryIdResult,
    getQueryId,
    getQueryIdOrders,
    setQueryEmailResult,
    getQueryEmail,
    getQueryEmailPage,
    getQueryEmailPageSize,
    getQueryEmailOrders,
    getQueryEmailOrderCount,
    setQueryZipResult,
    getQueryZip,
    getQueryZipPage,
    getQueryZipPageSize,
    getQueryZipOrders,
    getQueryZipOrderCount
} from '../../common/browser/localStorage';
import { useNavigate, useLocation } from 'react-router-dom';
import useQuery from '../../common/hooks/useQuery';
import OrderService from '../../services/OrderService';
import { Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';

import notifyError from '../../common/errors/notifyError';
import { Notification } from '../../common/components/common';
import useAppContext from '../../common/hooks/useAppContext';
import { useStyles as useBaseStyles } from "../../common/styles";

const useStyles = makeStyles(theme => ({
    select: {
        height: 19,
        width: 52,
        borderRadius: 5,
        fontSize: 13,
        borderColor: theme.palette.primary.light
    }
}))

const OrderList = ({ ordersFunct }) => {
    const classes = useStyles();
    const baseClasses = useBaseStyles();
    const [prevSelOrder, setPrevSelOrder] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [page, setPage] = useState(1);
    const [orders, setOrders] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [pageSize, setPageSize] = useState(20); //orders per page
    const [count, setCount] = useState(0); // total number of all orders that is indexed by page and pageCount
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const searchQuery = useQuery();
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' });
    const [isLoading, setIsLoading] = useState(true);
    const { store } = useAppContext();

    const updateSelectedOrder = (newOrder) => {
        setPrevSelOrder(selectedOrder);
        setSelectedOrder(newOrder);
    }

    const clearSelectedOrder = () => {
        setPrevSelOrder(null);
        setSelectedOrder(null);
    }

    const isUpdated = (response) => {
        if (response.data.count > 0) {
            return (!selectedOrder  // empty to non-empty
                || count !== response.data.count //non-empty to non-empty
                || pageCount !== Math.ceil(response.data.count / pageSize) //pageSize changes, so pageCount changes
                || JSON.stringify(orders[0]) !== JSON.stringify(response.data.orders[0]))
        } else { // non-empty to empty order
            return selectedOrder;
        }
    }

    const updateCommon = (response) => {
        setOrders(response.data.orders);
        setCount(response.data.count);
        setPageCount(Math.ceil(response.data.count / pageSize));  // 400 items / 20 = 20
        if (response.data.orders.length > 0) {
            updateSelectedOrder(response.data.orders[0]);
        } else {
            clearSelectedOrder();
        }
    }

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const orderId = searchQuery.get('id');
        const email = searchQuery.get('email');
        const zip = searchQuery.get('zip');
        let filter = "";

        if (orderId || email || zip) {
            if (orderId) {
                if (orderId === getQueryId()) {
                    const requestOrders = getQueryIdOrders();
                    setPageCount(1); // only one page
                    setOrders(requestOrders);
                    if (requestOrders?.length > 0) {
                        updateSelectedOrder(requestOrders[0]);
                    }
                    setIsLoading(false);
                } else {
                    filter = `id=${orderId}`;
                    OrderService.searchOrders(axiosPrivate, filter, page, pageSize)
                        .then(response => {
                            if (!isMounted) {
                                return;
                            }
                            // point: response.data.orders?.length > 0 means 
                            // response.data.orders > 0 && response.data.orders.length > 0
                            if (isUpdated(response)) {
                                updateCommon(response);
                                setQueryIdResult(orderId, response.data.orders);
                            }
                            setIsLoading(false);
                        })
                        .catch(err => {
                            isMounted && setIsLoading(false);
                            notifyError(err, setNotify, navigate, location);
                        });
                }
            }

            if (email) {
                if (email === getQueryEmail() && page === getQueryEmailPage() && pageSize === getQueryEmailPageSize()) {
                    const requestOrders = getQueryEmailOrders();
                    setPageCount(Math.ceil(getQueryEmailOrderCount() / getQueryEmailPageSize()));
                    setOrders(requestOrders);
                    if (requestOrders?.length > 0) {
                        updateSelectedOrder(requestOrders[0]);
                    }
                    setIsLoading(false);
                } else {
                    filter = `email=${email}`;
                    OrderService.searchOrders(axiosPrivate, filter, page, pageSize)
                        .then(response => {
                            if (!isMounted) {
                                return;
                            }
                            if (isUpdated(response)) {
                                updateCommon(response);
                                setQueryEmailResult(email, page, pageSize, response.data.count, response.data.orders);
                            }
                            setIsLoading(false);
                        })
                        .catch(err => {
                            isMounted && setIsLoading(false);
                            notifyError(err, setNotify, navigate, location);
                        });
                }
            }

            if (zip) {
                if (zip === getQueryZip() && page === getQueryZipPage()) {
                    const requestOrders = getQueryZipOrders();
                    setPageCount(Math.ceil(getQueryZipOrderCount() / getQueryZipPageSize()));
                    setOrders(requestOrders);
                    if (requestOrders?.length > 0) {
                        updateSelectedOrder(requestOrders[0]);
                    }
                    setIsLoading(false);
                } else {
                    filter = `zip=${zip}`
                    OrderService.searchOrders(axiosPrivate, filter, page, pageSize)
                        .then(response => {
                            if (!isMounted) {
                                return;
                            }
                            if (isUpdated(response)) {
                                updateCommon(response);
                                setQueryZipResult(zip, page, pageSize, response.data.count, response.data.orders);
                            }
                            setIsLoading(false);
                        })
                        .catch(err => {
                            isMounted && setIsLoading(false);
                            notifyError(err, setNotify, navigate, location);
                        });
                }
            }

        } else {
            ordersFunct(axiosPrivate, page, pageSize)
                .then(response => {
                    if (!isMounted) {
                        return;
                    }

                    if (isUpdated(response)) {
                        updateCommon(response);
                    }
                    setIsLoading(false);
                })
                .catch(err => {
                    isMounted && setIsLoading(false);
                    notifyError(err, setNotify, navigate, location);
                });
        }

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [ordersFunct, axiosPrivate, page, pageSize, location, navigate, store]);

    return (
        <>
            {isLoading ?
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                }}>
                    <CircularProgress sx={{
                        marginTop: 10,
                        marginBottom: 10,
                        mx: 'auto'
                    }} />
                </Box> :
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    overflowX: "scroll",
                    // justifyContent: "flex-end" //DO NOT USE THIS WITH 'scroll'
                }}>
                    <Box sx={{
                        minWidth: 250,
                        width: '23%',
                        height: 700,
                        borderRight: 1,
                        borderColor: '#D3D3D3',
                        overflowX: "scroll",
                        overflowY: "scroll",
                        display: "flex",
                        flexDirection: "column"
                    }} > {/*cannot add divider within grid*/}
                        <Box display="flex" sx={{ pl: 2, mt: 1.5, alignItems: "flex-end" }}>
                            <Typography sx={{ fontSize: 16 }} align="left">Orders</Typography>
                            <Box display="flex" sx={{ alignItems: "center", mb: 0.2, ml: 2 }} className={baseClasses.secondary}>
                                <Typography sx={{ fontSize: 11, mr: 1 }} align="left">Views</Typography>
                                <select
                                    value={pageSize}
                                    onChange={(e) => {
                                        setPageSize(e.target.value);
                                    }}
                                    className={classes.select}
                                >
                                    <option>10</option>
                                    <option>20</option>
                                    <option>30</option>
                                    <option>50</option>
                                    <option>100</option>
                                </select>
                                <Typography sx={{ fontSize: 11, ml: 1 }} align="left">Per Page</Typography>
                            </Box>
                        </Box>
                        <AppPagination setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} pageCount={pageCount} />
                        <OrderMenu sx={{ minWidth: 250 }} orders={orders} prevSelOrder={prevSelOrder} selectedOrder={selectedOrder}
                            updateSelectedOrder={updateSelectedOrder} />
                        {/* <AppPagination setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} pageCount={pageCount} /> */}
                    </Box>
                    <Box sx={{
                        px: 2,
                        minWidth: 300,
                        width: '75%',
                        height: 700,
                        overflowY: "scroll"
                    }}>
                        {selectedOrder ?
                            <OrderDetail order={selectedOrder} />
                            : <Box></Box>
                        }
                    </Box>
                </Box >
            }
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
        </>
    )
}

export default OrderList;