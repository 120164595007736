import React, { useState, useEffect } from 'react';
import { Button } from '../common/inputs';
import { Grid, Checkbox, FormControlLabel } from '@mui/material';
import { BillingService } from "../../services/BillingService";
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useStyles } from '../../styles';
import CardInput from './CardInput';
import { useCard } from './useCard';
import displayError from '../../errors/displayErrorAfterLogin';

let initCard = {
    id: 0,
    name: '',
    email: '',
    country: 'US',
    line1: '',
    city: '',
    state: '',
    postal_code: ''
}

export default function CardForm({ addOrEdit, recordForEdit }) {
    const classes = useStyles();

    if (recordForEdit) {
        const { id, name, email, address } = recordForEdit.billing_details;
        initCard = { id, name, email, ...address }
    }

    const stripe = useStripe();
    const elements = useElements();
    const axiosPrivate = useAxiosPrivate();
    const [isDefault, setIsDefault] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    const {
        values,
        setValues,
        errors,
        handleInputChange,
        resetForm,
        validate
    } = useCard(initCard);


    useEffect(() => {
        setErrMsg('');
    }, [values])

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            if (validate()) {
                const { id, name, email, ...rest } = values;
                const billing_details = {
                    name,
                    email,
                    address: rest
                }

                if (!recordForEdit) {
                    const { data: { setupIntent_client_secret } } = await BillingService.getSetupIntentClientSecret(axiosPrivate);

                    const { error, setupIntent } = await stripe.confirmCardSetup(setupIntent_client_secret, {
                        payment_method: {
                            card: elements.getElement(CardElement),
                            billing_details
                        },
                        return_url: process.env.REACT_APP_URL,
                    });


                    // Handle result.error or result.setupIntent
                    if (error) {
                        setErrMsg(error.message);
                        return;
                    }

                    if (setupIntent.status !== "succeeded") {
                        setErrMsg("Card Error:" + setupIntent.status);
                        console.log(setupIntent);
                        return;
                    }

                    addOrEdit(setupIntent.payment_method, isDefault, resetForm);
                } else {

                }
            }

        } catch (err) {
            displayError(err, setErrMsg);
        }
    }

    return (
        <>
            <p className={errMsg ? "alert-danger" : "offscreen"} aria-live="assertive" >{errMsg}</p >
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    {/* recordForEdit ?
                <>
                    <Grid item xs={6}>
                        <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
                    </Grid>
                    <Grid item xs={6}>
                        <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
                    </Grid>
                </> : */}

                    <CardInput
                        recordForEdit={recordForEdit}
                        values={values}
                        setValues={setValues}
                        errors={errors}
                        handleInputChange={handleInputChange}
                    />

                    <Grid item xs={12}>
                        <FormControlLabel
                            label="Set as default payment method."
                            control={
                                <Checkbox
                                    checked={isDefault}
                                    onChange={(e) => { setIsDefault(e.target.checked) }}
                                />
                            }
                        />
                    </Grid>

                    <Grid item xs={12} className={classes.right}>

                        <div>
                            <Button
                                type="submit"
                                text="Submit" />
                            <Button
                                text="Reset"
                                color="grey"
                                onClick={resetForm} />
                        </div>
                    </Grid>
                </Grid>
            </form >
        </>
    )
}

