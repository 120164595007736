import React, { useEffect, useState } from 'react';
import { Typography, Grid, Box, Divider } from '@mui/material';
import { DisplayResult } from './OrderUtil';
import { useStyles } from '../../common/styles';
import useAppContext from '../../common/hooks/useAppContext';

function OrderMenu({ orders, prevSelOrder, selectedOrder, updateSelectedOrder }) {
    const classes = useStyles();
    const { store } = useAppContext();
    const abbrevStoreName = store?.abbrevStoreName;

    useEffect(() => {
        if (prevSelOrder) // may not exist, because storeId or search condition changes in admin.spotfraud.app
            document.querySelector(`#order_${prevSelOrder.id}`)?.classList.remove('selected');
        if (selectedOrder)
            document.querySelector(`#order_${selectedOrder.id}`).classList.add('selected');
    }, [selectedOrder]);


    const handleClick = (e, newOrder) => {
        e.preventDefault();
        if (selectedOrder.id !== newOrder.id) {
            updateSelectedOrder(newOrder);
        }
    }
    return (
        <Box sx={{
            backgroundColor: 'white',
            pl: 2
        }}>
            <Divider sx={{ mt: 2 }} />
            {orders.length === 0 ?
                <Typography>No Orders</Typography> :
                orders.map((order, index) => (
                    <div key={order.id} >
                        <Grid container key={order.id} className='item' id={`order_${order.id}`}
                            sx={{ pb: 1 }}
                            onClick={(e) => handleClick(e, order)}
                        >
                            <Grid item key={1} xs={4} className={classes.secondary}>
                                <Typography sx={{ width: '100%', fontSize: 9 }}>
                                    {order.abbrevStoreName || abbrevStoreName}
                                </Typography>
                            </Grid>

                            <Grid item key={2} xs={4} className={classes.secondary}>
                                <Typography sx={{ width: '100%', fontSize: 9 }}>
                                    {order.currency_symbol}{order.total}
                                </Typography>
                            </Grid>

                            <Grid item key={3} xs={4} className={classes.secondary}>
                                <Typography sx={{ width: '100%', fontSize: 9 }}>
                                    {order.date}
                                </Typography>
                            </Grid>

                            <Grid item key={4} xs={5}>
                                <Typography sx={{ width: '100%', fontSize: 13 }}>
                                    {order.id}
                                </Typography>
                                <Typography sx={{ width: '100%', fontSize: 11 }}>
                                    {order.billing_address.first_name} {order.billing_address.last_name}
                                </Typography>
                            </Grid>

                            <Grid item key={6} xs={7} sx={{ pt: 0.8 }} className={classes.middle}>
                                <DisplayResult order={order} />
                            </Grid>
                        </Grid>
                        <Divider />
                    </div>
                ))
            }
        </Box >
    );
}

export default React.memo(OrderMenu);

